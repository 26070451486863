<template>
  <div class='finish-component background'>
    <template v-if="info">
      <h2 class='title'>{{ $t('invitation.hi')}}</h2>
      <p class="text">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium alias aliquam animi asperiores atque aut beatae corporis culpa deleniti dolores, doloribus eaque earum, eos explicabo ipsum iste maiores molestiae molestias nisi nobis quae repellat repellendus reprehenderit saepe voluptates! Aut, repudiandae!
      </p>
      <h2 class='title mb-2'>{{ $t('invitation.details') }}:</h2>
      <table v-if="info" class='table w-100'>
        <tr>
          <td>Style</td>
          <td>{{ info.style }}</td>
        </tr>
        <tr>
          <td>Course</td>
          <td>{{ info.course }}</td>
        </tr>
        <tr>
          <td>Race</td>
          <td>{{ info.race }}</td>
        </tr>
        <tr>
          <td>Competitors</td>
          <td>{{ info.competitors }}</td>
        </tr>
        <tr>
          <td>Gender</td>
          <td>{{ info.gender }}</td>
        </tr>
        <tr>
          <td>Performance Deadline</td>
          <td>{{ info.event_at }}</td>
        </tr>
      </table>
      <a-button class="mx-0 mt-auto" size='large' @click='goToSign'>
        {{ $t('invitation.sign') }}
        <a-icon type="arrow-right" />
      </a-button>
    </template>
    <template v-else>
      <h2 class='title'>{{ $t('invitation.closeTitle') }}</h2>
      <p class="text">{{ $t('invitation.closeText') }}</p>
      <div class='btns'>
        <a-button class="mx-0 mt-auto" size='large' @click="$router.push({name: 'registration'})">
          {{ $t('invitation.signUp') }}
        </a-button>
        <a-button type="default" class="mx-0 mt-auto" size='large' @click="$router.push({name: 'home'})">
          {{ $t('invitation.close') }}
        </a-button>
      </div>
    </template>
  </div>
</template>

<script>
import { getEventHash } from '@/api/public/event'

export default {
  name: "Invitation",
  data() {
    return {
      info: null
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo(){
      try{
        const { data } = await getEventHash(this.$route.params.hash);
        this.info = data
      }catch(e){
        console.error(e)
      }
    },
    goToSign() {
      this.$store.dispatch("eventHash/set", {
        id: this.info.event_id,
        type: this.info.event_type,
        hash: this.$route.params.hash,
      });
      this.$router.push({name: "registration"})
    }
  }
}
</script>

<style scoped>
  .finish-component{
    overflow: auto;
    flex-direction: column;
    padding: 40px 24px;
    justify-content: flex-start;
    align-items: flex-start;
    color: rgba(255,255,255,0.9);
    font-family: 'Manrope', sans-serif;
    font-size: 15px;
    line-height: 1.5;
  }
  .title{
    text-align: left;
    color: inherit;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }
  .text{
    margin-bottom: 20px;
    opacity: .85;
  }
  .table{
    font-size: 16px;
  }
  .table td{
    padding-bottom: 6px;
  }
  .table td:last-child{
    font-weight: 600;
    text-align: right;
  }
  .table td:first-child{
    opacity: .85;
  }
  .btns{
    display: flex;
    gap: 12px;
    width: 100%;
    margin-top: auto;
  }
  .btns .ant-btn{
    flex-grow: 1;
  }
  .btns .ant-btn-default{
    background-color: transparent;
    color: inherit;
  }
</style>