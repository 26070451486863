import axios from '@/plugins/axios'

export function getEventHash (hash) {
  return axios.get(`/public/hash-event/${hash}`)
}
export function getIdFromRedirect (id,type) {
  return axios.get(`/profile/competitions/by-event`, {
    params: {
      event_id: id,
      event_type: type
    }
  })
}
export default {
  getEventHash,
  getIdFromRedirect
}
